<template>
  <div>
    <div class="form-wrapper mb-1">
      <h3 class="font-weight-bolder mb-1">
        {{ $t(ASSET_TABLE_TITLE) }}
      </h3>
      <asset-skeleton />
    </div>
    <div class="form-wrapper mb-1">
      <h3 class="font-weight-bolder mb-1">
        {{ $t(SERVICE_ORDER_INFORMATION_TITLE) }}
      </h3>

      <b-row cols-lg="mb-1">
        <b-col
          v-for="({ value, title }) in serviceType"
          :key="value"
        >
          <div class="d-flex justify-content-between">
            <span>
              {{ title }}
            </span>
            <b-skeleton
              class="rounded-circle"
              style="position: relative; top: 2px"
              width="18px"
              height="18px"
            />
          </div>
        </b-col>
      </b-row>
      <div>
        <b class="pl-1">{{ $t('Supplier Name') }}</b>
        <b-skeleton
          width="100%"
          height="38px"
        />
      </div>
      <b-row>
        <b-col>
          <div
            v-for="(item, indx) in Object.keys(serviceOrderInformationFields).slice(1, 7)"
            :key="indx"
          >
            <b class="pl-1">{{ $t(serviceOrderInformationFields[item].label) }}</b>
            <b-skeleton
              width="100%"
              height="38px"
            />
          </div>
        </b-col>
        <b-col>
          <div>
            <div class="d-flex flex-column mb-1">
              <feather-icon
                style="min-width: 37px; min-height: 37px; opacity: 0.5"
                icon="LAddButtonIcon"
                size="24"
                class="cursor-pointer feather-add-icon ml-auto opacity-50"
              />
            </div>
            <b-skeleton
              width="100%"
              height="30px"
              style="margin-bottom: 2px"
            />
            <b-skeleton
              width="100%"
              height="30px"
            />
          </div>
          <div
            class="border-dashed d-flex justify-content-center align-items-center mb-1"
            style="height: 116px"
          >
            <p
              class="l-file-upload__dragzone-text"
              style="opacity: 0.5"
            >
              {{ $t('Drop Files Here to Upload') }}
            </p>

          </div>
          <div
            v-for="(item, indx) in Object.keys(serviceOrderInformationFields).slice(7, 9)"
            :key="indx"
          >
            <b class="pl-1">{{ $t(serviceOrderInformationFields[item].label) }}</b>
            <b-skeleton
              width="100%"
              height="70px"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="form-wrapper mb-1">
      <h3 class="font-weight-bolder mb-1">
        {{ $t(DISPATCH_AND_RETURN_FORM_TITLE) }}
      </h3>
      <b-row>
        <b-col>
          <div
            v-for="(item, indx) in Object.keys(dispatchAndReturnFields).slice(0, 3)"
            :key="indx"
          >
            <b class="pl-1">{{ $t(dispatchAndReturnFields[item].label) }}</b>
            <b-skeleton
              width="100%"
              height="38px"
            />
          </div>
        </b-col>
        <b-col>
          <div
            v-for="(item, indx) in Object.keys(dispatchAndReturnFields).slice(3)"
            :key="indx"
          >
            <b class="pl-1">{{ $t(dispatchAndReturnFields[item].label) }}</b>
            <b-skeleton
              width="100%"
              height="38px"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import AssetSkeleton from '@/views/main/orders/view/service/components/common/AssetSkeleton.vue'
import { BSkeleton, BRow, BCol } from 'bootstrap-vue'
import mainConfig from '@/views/main/orders/view/service/config'
import config from '../config'

export default {
  name: 'Skelton',
  components: {
    BRow,
    BCol,
    BSkeleton,
    AssetSkeleton,
    QuoteStatusBar,
  },
  setup() {
    const {
      serviceType,
      ASSET_TABLE_TITLE,
      dispatchAndReturnFields,
      serviceOrderInformationFields,
      DISPATCH_FORM_TITLE,
      DISPATCH_AND_RETURN_FORM_TITLE,
      RETURN_FORM_TITLE,
      SERVICE_ORDER_INFORMATION_TITLE,
    } = config()
    const { quoteStatusBar } = mainConfig()

    return {
      serviceType,
      quoteStatusBar,
      ASSET_TABLE_TITLE,
      dispatchAndReturnFields,
      serviceOrderInformationFields,
      DISPATCH_FORM_TITLE,
      DISPATCH_AND_RETURN_FORM_TITLE,
      RETURN_FORM_TITLE,
      SERVICE_ORDER_INFORMATION_TITLE,
    }
  },
}
</script>
